// extracted by mini-css-extract-plugin
export var card = "project-card-module--card--pf5dT";
export var bgImage = "project-card-module--bgImage--vXQtA";
export var content = "project-card-module--content--CA-vr";
export var title = "project-card-module--title--DDjJN";
export var description = "project-card-module--description--agxFv";
export var buttons = "project-card-module--buttons--oFjN7";
export var whiteBtn = "project-card-module--whiteBtn--td3Jz";
export var quiet = "project-card-module--quiet--3BsmQ";
export var dark = "project-card-module--dark--j7Qbh";
export var light = "project-card-module--light--DN+Nj";
export var hoverable = "project-card-module--hoverable--NfzgZ";
export var loadmore = "project-card-module--loadmore--lFjvA";
export var hidden = "project-card-module--hidden--5hlc4";