import Helmet from 'react-helmet'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { isBrowser } from '../utils/isBrowser'

interface Image {
    publicURL: string
}

interface SEOProps {
    title?: string
    description?: string
    metadataSocialImage?: string
    metadataSocialTitle?: string
    metadataSocialDescription?: string
    meta?: [{ name: string; content: string; property?: undefined }]
    siteMetadata: {
        title: string
        description: string
        image?: Image
        twitterCreator: string
    }
}

export const SEO: React.FC<SEOProps> = (props: SEOProps) => {
    // Getting props
    const { title, description, siteMetadata, meta = [] } = props

    // Getting metadata title and description,
    // falling back to site title and description
    const metaTitle = title || siteMetadata?.title
    const metaDescription = description || siteMetadata?.description

    // Getting metadata social title and description,
    // falling back to meta title and description
    const metadataSocialTitle = props.metadataSocialTitle || metaTitle
    const metadataSocialDescription =
        props.metadataSocialDescription || metaDescription

    // Getting page metadata image
    let metadataImage =
        props?.metadataSocialImage?.url ?? siteMetadata?.image?.url
    if (metadataImage && !metadataImage.startsWith('https://') && isBrowser()) {
        metadataImage = `${location.origin}${metadataImage}`
    }
    return (
        <Helmet
            title={title}
            defaultTitle={siteMetadata?.title}
            titleTemplate={`${siteMetadata?.title} | %s`}
            meta={[
                {
                    name: 'description',
                    content: metaDescription,
                },
                {
                    property: 'og:title',
                    content: metadataSocialTitle,
                },
                {
                    property: 'og:description',
                    content: metadataSocialDescription,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    property: 'og:image',
                    content: metadataImage,
                },
                {
                    name: 'twitter:image',
                    content: metadataImage,
                },
                {
                    name: 'twitter:card',
                    content: 'summary',
                },
                {
                    name: 'twitter:creator',
                    content: siteMetadata.twitterCreator,
                },
                {
                    name: 'twitter:title',
                    content: metadataSocialTitle,
                },
                {
                    name: 'twitter:description',
                    content: metadataSocialDescription,
                },
            ].concat(meta)}
        />
    )
}
