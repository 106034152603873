import React from 'react'
import classNames from 'classnames'
import * as styles from './project-card.module.less'
import { Button } from '@adobe/react-spectrum'
import { richTextToHtml } from '../utils/richTextToHtml'
import { withPrefix } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { rawListeners } from 'process'
import { isMobile } from '../utils/isMobile'

interface ProjectCardProps {
    cardImage: any
    themeColor: string
    themeVariant: string
    cardTitle: string
    defaultTitle: string
    description: {
        content: unknown[]
    }
    heroSubtitle: {
        content: unknown[]
    }
    cardSubtitle: string
    externalUrl: string
    mobileExternalUrl: string
    directExternalLink: boolean
    slug: string
}

export default function ProjectCard(props: ProjectCardProps) {
    const themeVariant = props.themeVariant ? props.themeVariant[0] : 'dark'
    const cardTitle = props.cardTitle || props.defaultTitle
    const cardImage = getImage(props.cardImage)
    const cardSubtitle =
        props.cardSubtitle || richTextToHtml(props.heroSubtitle)

    return (
        <div className={classNames(styles.card, styles[themeVariant])}>
            {cardImage && (
                <GatsbyImage
                    image={cardImage}
                    className={styles.bgImage}
                    alt=""
                />
            )}
            <div
                className={styles.content}
                style={{
                    backgroundColor: !cardImage
                        ? props.themeColor
                        : 'transparent',
                }}
            >
                <div className={styles.title}>{cardTitle}</div>
                <div className={styles.description}>{cardSubtitle}</div>
                <div className={styles.buttons}>
                    {!props.directExternalLink && (
                        <Button
                            variant="primary"
                            UNSAFE_className={classNames(styles.whiteBtn)}
                            elementType="a"
                            href={withPrefix(`/projects/${props.slug}/`)}
                        >
                            Learn more
                        </Button>
                    )}

                    {(props.externalUrl || props.mobileExternalUrl) && (
                        <Button
                            variant="primary"
                            UNSAFE_className={classNames(
                                styles.whiteBtn,
                                !props.directExternalLink && styles.quiet
                            )}
                            isQuiet
                            elementType="a"
                            href={
                                isMobile() && props.mobileExternalUrl
                                    ? props.mobileExternalUrl
                                    : props.externalUrl
                            }
                            target="_blank"
                        >
                            {props.ctaButtonLabel || 'Try it out'}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}
